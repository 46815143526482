import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import styles from '../../styles/Breadcrumb.module.css';
import Container from './Container';

type CrumbProps = {
  text: string;
  href: string;
  last: boolean;
};

const Crumb = ({ text, href, last = false }: CrumbProps) => {
  if (last) {
    return <span>{text}</span>;
  }

  return (
    <Link href={href} prefetch={false}>
      {text}
    </Link>
  );
};

type BreadcrumbProps = {
  titles: any;
  omitRoot?: boolean;
};

const Breadcrumb = ({ titles, omitRoot }: BreadcrumbProps) => {
  const router = useRouter();

  const breadcrumbs = React.useMemo(
    () => {
      const asPathWithoutQuery = router.asPath.split('?')[0];

      if (asPathWithoutQuery) {
        const asPathNestedRoutes = asPathWithoutQuery
          .split('/')
          .filter((v) => v.length > 0);

        const crumblist = asPathNestedRoutes.map((subpath, idx) => {
          const href = `/${asPathNestedRoutes.slice(0, idx + 1).join('/')}`;
          const title = subpath ? subpath.replace(/-/g, ' ') : subpath;
          return {
            href,
            text: titles[subpath] || title,
          };
        });

        return omitRoot
          ? [...crumblist]
          : [{ href: '/', text: 'Home' }, ...crumblist];
      }
      return omitRoot ? [] : [{ href: '/', text: 'Home' }];
    },
    [router.asPath]
  );

  if (!breadcrumbs) {
    return null;
  }

  return (
    <nav className={styles.breadcrumb} aria-label="Breadcrumb">
      {breadcrumbs.length > 0 && (
        <ul>
          {breadcrumbs.map((crumb, idx) => (
            <li key={idx}>
              <Crumb {...crumb} last={idx === breadcrumbs.length - 1} />
            </li>
          ))}
        </ul>
      )}
    </nav>
  );
};

type BreadcrumbsProps = {
  omitRoot?: boolean;
};

export default function Breadcrumbs({ omitRoot }: BreadcrumbsProps) {
  const defaultTitles = {};

  return (
    <Container size="xl">
      <Breadcrumb titles={defaultTitles} omitRoot={omitRoot} />
    </Container>
  );
}
