import Meta from '../components/Meta';
import Breadcrumb from '../components/ui/Breadcrumb';
import Container from '../components/ui/Container';
import LayoutMain from '../layouts/LayoutMain';

const Custom404 = () => (
    <>
      <Meta title="404 - Page Not Found" />

      <Breadcrumb />

      <Container size="xl">
        <h1 className="mt-8">404 - Page Not Found</h1>
        <p className="text-center">
          We&rsquo;re sorry, but the requested page cannot be found.
        </p>
      </Container>
    </>
);

Custom404.Layout = LayoutMain;
export default Custom404;
